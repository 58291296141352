
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productNotesRT () {
    function repeatNote1(notes, note, noteIndex) {
        return _createElement('li', { 'className': 'cm_product-note' }, note);
    }
    function scopeNotes2() {
        var notes = this.productData.find(p => p.field === 'product_notes')?.value;
        return _createElement('div', { 'className': 'cm_product-notes-container' }, notes ? [
            '\n    Notes:\n    ',
            _createElement.apply(this, [
                'ul',
                {
                    'className': 'cm_product-notes',
                    'key': '1401'
                },
                _map(notes, repeatNote1.bind(this, notes))
            ])
        ] : null);
    }
    return scopeNotes2.apply(this, []);
}
        export const componentNames = []