import shopifyGenericDefaults from '../shopify-dawn-generic/config.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox').location = {
  selector: '#cm-search-box',
};
shopifyGenericDefaults.Widgets.find((w) => w.name === 'VerifyFitment').columnBreakpoint = 400;

shopifyGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FacetPanel' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('category');
    } else {
      w.ignoreFields = ['category'];
    }
  }
  return w;
});

['HeaderVehicleWidget', 'SearchVehicleWidget', 'VehicleWidgetDialog', 'VerifyFitment'].forEach(
  (name) => (shopifyGenericDefaults.Widgets.find((w) => w.name === name).useSearchableDropdown = true),
);

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyGenericDefaults,
  doAutocompleteRedirect: true,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    isExtraFieldsAlwaysVisible: true,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets,
    {
      name: 'ProductNotes',
      type: 'ProductData',
      location: { lastChildOf: '.product product-info' },
      template: 'product/productNotes',
      fields: ['product_notes'],
    },
  ],
};
